import { Alert, Center, Paper, Title, Text, Anchor } from '@mantine/core';
import { useRouter } from 'next/router';
import React from 'react';
import AuditLoginForm from '../components/Forms/AuditLoginForm';
import LoginForm from '../components/Forms/LoginForm';
import MainAppShell from '../components/Layout/MainAppShell';
import { IS_AUDIT, IS_CANARY, IS_STAGING } from '../constants';
import { OrganizationType } from '../graphql/types.generated';
import { useAlreadyLoggedIn } from '../hooks/useSession';

const Auth = () => {
  const router = useRouter();
  const setDisabled = useAlreadyLoggedIn();

  return (
    <MainAppShell>
      <Center h="80vh">
        <Paper p="lg" shadow="xs" style={{ width: 600 }}>
          {router.query?.error && (
            <Alert title="Error with logging in" color="red" mb="md">
              {router.query.error}
            </Alert>
          )}
          <Title order={3} mb="lg">
            Log in to your account
          </Title>
          {IS_AUDIT ? (
            <AuditLoginForm
              onSuccess={(currentSession) => {
                if (router.query.returnTo) {
                  router.replace(router.query.returnTo as string);

                  return setDisabled();
                }

                if (currentSession?.organization?.type === OrganizationType.Shepherd) {
                  router.push(`/u`);

                  return setDisabled();
                }

                return setDisabled();
              }}
            />
          ) : null}
          {IS_STAGING || IS_CANARY ? (
            <Text>
              Please go to
              <Anchor href="https://shepherd.insure/login" color="blue">
                {' '}
                Production Login{' '}
              </Anchor>
              to login, then come back to Staging or Canary.
            </Text>
          ) : (
            <LoginForm />
          )}
        </Paper>
      </Center>
    </MainAppShell>
  );
};

export default Auth;
